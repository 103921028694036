html, #app {
  min-width: 320px;
  max-width: 600px;
  margin: 0 auto;
}

body #root {
  justify-content: center;
  width: 100%;
  height: 100%;
  font-family: Lato, sans-serif;
  display: flex;
}

/*# sourceMappingURL=base.23a928ee.css.map */
